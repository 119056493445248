import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["wrapper", "overlay", "offcanvas"]

  keydown(e) {
    if (e.key === "Escape") {
      this.close()
    }
  }

  open() {
    this.offcanvasTarget.classList.remove("translate-x-full")
    this.overlayTarget.classList.remove("hidden", "opacity-0")
    this.overlayTarget.classList.add("opacity-100")
  }

  close() {
    this.offcanvasTarget.classList.add("translate-x-full")
    this.overlayTarget.classList.add("hidden", "opacity-0")
    this.overlayTarget.classList.remove("opacity-100")
  }
}
