import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    document.body.classList.add("overflow-hidden")
  }

  disconnect() {
    document.body.classList.remove("overflow-hidden")
    this.modalTurboFrame.src = null
  }

  keydown(e) {
    if (e.key === "Escape") {
      this.close()
    }
  }

  close() {
    this.element.remove()
  }

  get modalTurboFrame() {
    return document.querySelector('turbo-frame[id="modal"]')
  }
}
