import { Controller } from "@hotwired/stimulus"
import { EditorView, drawSelection, keymap, lineNumbers } from "@codemirror/view"
import { defaultKeymap, history, historyKeymap, indentWithTab } from "@codemirror/commands"
import { syntaxHighlighting, defaultHighlightStyle } from "@codemirror/language"
import { html } from "@codemirror/lang-html"

export default class extends Controller {
  static targets = ["field", "editor"]

  static values = {
    content: String,
  }

  connect() {
    this.view = new EditorView({
      doc: this.fieldTarget.value,
      extensions: this.extensions,
      parent: this.editorTarget,
    })
  }

  get extensions() {
    // prettier-ignore
    return [
      keymap.of([...defaultKeymap, ...historyKeymap, indentWithTab]),
      history(),
      drawSelection(),
      syntaxHighlighting(defaultHighlightStyle),
      lineNumbers(),
      html(),
      EditorView.updateListener.of((e) => {
        this.fieldTarget.value = this.view.state.sliceDoc()
      }),
    ]
  }
}
