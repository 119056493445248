import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "error"]

  change() {
    // delete the error message if it exists
    if (this.hasErrorTarget) {
      this.errorTarget.remove()
    }

    // remove the has error class from the input field
    this.inputTarget.classList.remove("form__input-field--has-error")
  }
}
