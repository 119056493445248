import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["param", "frame"]

  static values = {
    url: String,
  }

  connect() {
    this.fetch()
  }

  fetch() {
    get(this.urlValue, {
      query: this.params,
      responseKind: "turbo-stream",
    })
  }

  get params() {
    let params = {}
    this.paramTargets.forEach((param) => (params[param.dataset.name] = param.value))
    this.#deleteBlankKeys(params)
    return params
  }

  #deleteBlankKeys(obj) {
    for (const key in obj) {
      if (!obj[key]) {
        delete obj[key]
      }
    }
  }
}
